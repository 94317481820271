import axios from "axios";
// import store from "./store";
// import { TOKEN_NAME } from "./utils/const";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem(TOKEN_NAME) || null;
    // if (token) {
    //   config.headers.Authorization = `${token}`;
    // }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  (response) => {
    // if (!response.data.success) {
    //   const { error } = response.data;
    //   if (error.type === 802) {
    //     store.commit("setUnauthorized", { show: true, text: error.message });
    //   }
    // }
    return response;
  },
  (error) => {
    console.log(error.response)
    if (!error.response) {
      console.log("Please check your internet connection.");
    }
    // if (error.response.status === 401) {
    //   localStorage.removeItem(TOKEN_NAME);
    //   window.location = "/login";
    // }
    return Promise.reject(error);
  },
);

export default axios;
