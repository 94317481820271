import { isMobile, isTablet } from 'mobile-device-detect'

Number.prototype.padLeft = function(base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};
Math.easeInOutQuad = function(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
export default {
  data() {
    return {
      isMobileDevice: isMobile,
      isTabletDevice: isTablet,
      heightHeaderApp: isMobile || isTablet ? 50 : 60,
      scrollSettings: { maxScrollbarLength: 100 },
      scrollOptions: { maxScrollbarLength: 100, suppressScrollX: true },
      baseURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    getElmHeight(node) {
      const list = [
        'margin-top',
        'margin-bottom',
        'border-top',
        'border-bottom',
        'padding-top',
        'padding-bottom',
        'height'
      ]
      
      const style = window.getComputedStyle(node)
      return list
          .map(k => parseInt(style.getPropertyValue(k), 10))
          .reduce((prev, cur) => prev + cur)
    },
    getAbsoluteHeight(el) {
    // Get the DOM Node if you pass in a string
      el = (typeof el === 'string') ? document.querySelector(el) : el; 
    
      var styles = window.getComputedStyle(el);
      var margin = parseFloat(styles['marginTop']) +
                  parseFloat(styles['marginBottom']);
    
      return Math.ceil(el.offsetHeight + margin);
    },
    validateFieldNumber(evt) {
      var theEvent = evt || window.event
      // Handle paste
      if (theEvent.type === "paste") {
        key = event.clipboardData.getData("text/plain")
      } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which
        key = String.fromCharCode(key)
      }
      var regex = /[0-9]|\./
      if (!regex.test(key)) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) theEvent.preventDefault()
      }
    },
    slugify(str) {
      let parteLabel = str.replace(/\s/g, "");
      parteLabel = parteLabel.replace(/,/g, "");
      parteLabel = parteLabel.replace(/\)/g, "");
      parteLabel = parteLabel.replace(/\(/g, "");
      const map = {
        a: "á|à|ã|â|ä|A|À|Á|Ã|Â|Ä",
        e: "é|è|ê|ë|E|É|È|Ê|Ë",
        i: "í|ì|î|ï|I|Í|Ì|Î|Ï",
        o: "ó|ò|ô|õ|ö|O|Ó|Ò|Ô|Õ|Ö",
        u: "ú|ù|û|ü|U|Ú|Ù|Û|Ü",
      };

      parteLabel = parteLabel.toLowerCase();

      for (const pattern in map) {
        parteLabel = parteLabel.replace(new RegExp(map[pattern], "g"), pattern);
      }
      return parteLabel;
    },
    isEmpty(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    validateEmail(email) {
      const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // var EMAIL_REGEXP = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
      return EMAIL_REGEXP.test(email);
    },
    uuidv4() {
      return "xxxx-xxxx-xyxx-yxxx".replace(/[xy]/g, function(
        c,
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  },
};
