<template>
  <div id="header-app" class="header-app">
    <div class="header">
      <div class="container">
        <div class="header__default">
          <div class="header__logo">
            <h1 class="mb-0">
              <router-link class="link-logo logo-app" to="/inicio">
                <img src="@/assets/equo-logo.png" alt=""><span class="d-none">Hotel Equo</span>
              </router-link>
            </h1>
          </div>
          <div class="header__options">
            <ul class="nav nav-menu">
              <li class="li-menu">
                <router-link to="/inicio">
                  <span class="mb-0">Inicio</span>
                </router-link>
              </li>
              <li class="li-menu">
                <router-link to="/nosotros">
                  <span class="mb-0">Nosotros</span>
                </router-link>
              </li>
              <li class="li-menu">
                <router-link to="/servicios">
                  <span class="mb-0">Servicios</span>
                </router-link>
              </li>
              <li class="li-menu">
                <router-link to="/habitaciones">
                  <span class="mb-0">Habitaciones y Reserva</span>
                </router-link>
              </li>
              <li class="li-menu">
                <router-link to="/contacto">
                  <span class="mb-0">Contáctanos</span>
                </router-link>
              </li>
              <li class="li-whatsapp">
                <a href="https://api.whatsapp.com/send?phone=51951298766" target="_blank">
                  <img src="@/assets/icon-whatsapp.png" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderSection',
  data:() => ({
    dialogSign: false,
    dialogForgot: false,
  }),
  mounted() {
  },
  methods: {
  }
}
</script>