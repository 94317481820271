const paths = [
  {
    path: "/",
    meta: {},
    name: "Root",
    props: true,
    redirect: {
      name: "HomePage",
    },
  },
  {
    path: '/inicio',
    name: 'HomePage',
    meta: {
      public: false,
    },
    component: () => import('../pages/HomePage.vue'),
  },
  {
    path: '/nosotros',
    name: 'AboutPage',
    meta: {
      public: false,
    },
    component: () => import('../pages/AboutPage.vue'),
  },
  {
    path: '/servicios',
    name: 'ServicePage',
    meta: {
      public: false,
    },
    component: () => import('../pages/ServicePage.vue'),
  },
  {
    path: '/habitaciones',
    name: 'RoomPage',
    meta: {
      public: false,
    },
    component: () => import('../pages/RoomPage.vue'),
  },
  {
    path: '/habitaciones/:room',
    name: 'RoomDetailPage',
    meta: {
      public: false,
    },
    component: () => import('../pages/RoomDetailPage.vue'),
  },
  {
    path: '/contacto',
    name: 'ContactPage',
    meta: {
      public: false,
    },
    component: () => import('../pages/ContactPage.vue'),
  },
];

export { paths }