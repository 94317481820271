<template>
  <div id="app">
    <header-section></header-section>
    <router-view></router-view>
    <footer-section v-if="!isHomePage"></footer-section>
  </div>
</template>

<script>
import HeaderSection from '@/layout/HeaderSection'
import FooterSection from '@/layout/FooterSection'

export default {
  name: 'App',
  components: {
    HeaderSection,
    FooterSection
  },
  data: () => ({
    loadingApp: true,
    dialogCompleteProfile: false,
    dialogParentProfile: false,
    signedIn: false,
  }),
  computed: {
    isHomePage() {
      return this.$route.name === 'HomePage'
    }
  },
  async created() {
  },
  mounted() {
  }
}
</script>
