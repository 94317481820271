 /* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
// Router
import router from './router'
// Http
import axios from './http'
// mixins
import Globals from './mixins'
// scss
import './scss/app.scss'

Vue.mixin(Globals);
Vue.prototype.$axios = axios;
Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
