<template>
  <div class="footer-section">
    <div class="footer">
      <div class="container ">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="footer-address">
              Avenida 28 de julio 245 Miraflores,<br />Lima, Perú | <b>Tel: 951298766</b>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="footer-social">
              <a class="footer-social--link" href="https://www.facebook.com/hotelequomiraflores" target="_blank">
                <img src="@/assets/facebook.png" />
              </a>
              <a class="footer-social--link" href="https://www.twitter.com/" target="_blank">
                <img src="@/assets/twitter.png" />
              </a>
              <a class="footer-social--link" href="https://www.instagram.com/" target="_blank">
                <img src="@/assets/instagram.png" />
              </a>
            </div>
          </div>
        </div>
        <div class="footer-copy">
          © 2021 by Equo Hotel
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterSection'
}
</script>